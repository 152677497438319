<template>
    <div class="resol-tree">

        <TaskItem
            v-for="item in sortByMain(items)"
            :key="item.id"
            :item="item"
            :knownExecuters="knownExecuters"
            :open-all="openAll"
            :active-id="activeId"
            :active-class="activeClass"
            :task-click="taskClickHandler"
            :task-dbl-click="taskDblClickHandler"
            :execution-click="executionClickHandler"
            :execution-dbl-click="executionDblClickHandler"
        />

    </div>
</template>

<script>
import TaskItem from '@/components/tabs/task-components/TaskItem.vue'

export default {
    name: "ResolutionTree",
    components: {
        TaskItem
    },
    data() {
        return {
            activeId: null,
            activeItem: null,
        }
    },
    props: {
        items: Array,
        activeClass: String,
        knownExecuters: Object,
        openAll: Boolean,
    },
    methods: {
        taskClickHandler(item) {
            if (this.activeId !== item.id) {
                this.activeId = item.id;
                this.activeItem = item;
                this.$emit('selected-change', item);
            }

            this.$emit('task-item-click', item);
        },
        taskDblClickHandler(item) {
            this.$emit('task-item-dbl-click', item);
        },
        executionClickHandler(item) {
            if (this.activeId !== item.executionId) {
                this.activeId = item.executionId;
                this.activeItem = item;
                this.$emit('selected-change', item);
            }
            
            this.$emit('execution-item-click', item);
        },
        executionDblClickHandler(item) {
            this.$emit('execution-item-dbl-click', item);
        },
        sortByMain(items) {
            return this.$_.orderBy(items, ['isMain', 'createDate'], ['desc', 'asc']);
        }
    }
}
</script>